import React from 'react'
import { graphql } from 'gatsby'

import PageBuilder from 'components/PageBuilder/PageBuilder'
import Wrap from 'components/Wrap'
import Seo from 'components/Seo'

import loadable from '@loadable/component'
// import Banner from 'components/Banner/Banner'
// import Popup from 'components/Popup'
const Popup = loadable(() => import('../../components/Popup'))
const Banner = loadable(() => import('../../components/Banner/Banner'))
export default function TemplateHome(props) {
  const { data } = props
  const { entry, globals } = data?.craft
  const { seoTitle, seoDescription, seoImage, titleSlogan } = entry
  const { pageContext } = props

  // Call to action buttons.
  const signUp = globals?.ctaSignUp
  const [curPage] = pageContext.alternateEntries
  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        defaults={data?.craft?.globals?.seo}
        pageContext={pageContext}
      />
      <Wrap minHeight="100vh">
        <PageBuilder
          pageBuilder={entry.pageBuilder}
          pageContext={pageContext}
        />
      </Wrap>
      <Banner curPageTitle={curPage.title} pageContext={pageContext} />
      <Popup pageContext={pageContext} />
    </>
  )
}

export const query = graphql`
  query TemplateHome($id: [Int]!, $siteId: Int!) {
    craft {
      # Globals
      globals(siteId: $siteId) {
        ...GlobalNavigationSocialQuery
        ...GlobalCtaSignUpQuery
        ...GlobalFooterSectionQuery
        ...GlobalCookieInfoQuery
        ...GlobalSeoQuery
        ...GlobalBannerQuery
        ...GlobalMembershipOptionsQuery
        ...GlobalLawTextQuery
      }

      clubs: entries(type: PagesPagesClubs) {
        ...ClubQuery
      }

      # NavigationHeader
      navigationHeaderItems: entries(
        section: navigationHeader
        siteId: $siteId
      ) {
        ...NavigationHeaderQuery
      }

      # NavigationFooter
      navigationFooterItems: entries(
        section: navigationFooter
        siteId: $siteId
      ) {
        ...NavigationFooterQuery
      }

      # Page
      entry(id: $id, siteId: $siteId) {
        ... on Craft_Home {
          pageBuilder {
            ...PageBuilderQuery
          }
        }
      }
    }
  }
`
